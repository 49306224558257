//课程的通用子级别-编辑
<template>
  <div class="sub_level" @click.stop="doOutClick">
    <div class="margin_left_14 margin_right_14 margin_top_10">
      <!-- 顶部的级别名称以及新增账户按钮 -->
      <div class="top">
        <!-- 级别名称 -->
        <span class="text_fw">{{ subItem.subName }}</span>
        <!-- 右边的新增按钮 -->
        <span class="edit_bt" @click.stop="addAccount">新增账户</span>
      </div>

      <!-- 试用账户 pid=5 模考不用显示试用账户 -->
      <div v-if="pid != 5">
        <!-- 试用账户 只有试用账户有数据的情况下才显示   -->
        <TryAccount
          ref="try_account"
          :pid="pid"
          @doEditAction="doEditTryAccountAction"
          :trial="subItem.trial"
          :mode="subItem.mode"
          v-if="subItem.trial"
        ></TryAccount>

        <!-- 试用账户无数据 -->
        <div class="trial_empty_view empty_tv margin_top_14" v-if="!subItem.trial && subItem.mode != 1">暂无试用账户</div>
      </div>

      <!-- 正式账户 -->
      <div>
        <!-- 正式账户 只有正式账户有数据的情况下才显示   -->
        <OfficialAccount
          @doEditAciton="doEditOfficialAccountAction"
          :subName="subItem.subName"
          :normal="subItem.normal"
          v-if="subItem.normal && subItem.normal.length > 0"
        ></OfficialAccount>

        <!-- 正式账户无数据 -->
        <div class="empty_view empty_tv margin_top_14" v-else>暂无正式账户</div>
      </div>
    </div>

    <!-- 新增账户的弹框组件 -->
    <AddAccount
      @refreshPage="refreshPage"
      :sid="sid"
      :pid="pid"
      :subId="subItem.subId"
      :subName="subItem.subName"
      v-if="isShowAddAccountDialog"
      title="新增账户"
      @close="closeAddAccount"
      :showDialog="isShowAddAccountDialog"
    ></AddAccount>

    <!-- 试用账户编辑的弹框组件 -->
    <EditTryAccountDialog
      @refreshPage="refreshPage"
      :sid="sid"
      :pid="pid"
      :subItem="subItem"
      v-if="isShowEditTryAccountDialog"
      title="修改试用账户"
      @close="isShowEditTryAccountDialog = false"
      :showDialog="isShowEditTryAccountDialog"
    ></EditTryAccountDialog>

    <!-- 正式账户编辑的弹框 -->
    <EditOfficialAccountDialog
      @refreshPage="refreshPage"
      :pid="pid"
      :editItem="editItem"
      v-if="isShowEditOfficialAccountDialog"
      title="修改正式账户"
      @close="isShowEditOfficialAccountDialog = false"
      :showDialog="isShowEditOfficialAccountDialog"
    ></EditOfficialAccountDialog>
  </div>
</template>
<script>
// 引入试用账户
import TryAccount from "@/components/updateproject/common/TryAccount";

//引入正式账户
import OfficialAccount from "@/components/updateproject/common/OfficialAccount";

//引入新增账户的弹框组件
import AddAccount from "@/components/dialog/AddAccount";

//试用账户编辑弹框
import EditTryAccountDialog from "@/components/dialog/EditTryAccountDialog";

//正式账户编辑弹框
import EditOfficialAccountDialog from "@/components/dialog/EditOfficialAccountDialog";

export default {
  data() {
    return {
      //新增账户的弹框控制值
      isShowAddAccountDialog: false,

      //试用账户的编辑弹框控制值
      isShowEditTryAccountDialog: false,

      //正式账户的编辑弹框控制值
      isShowEditOfficialAccountDialog: false,

      //编辑的正式条目
      editItem: null,
    };
  },
  created() {},
  methods: {
    //新增账户
    addAccount() {
      this.isShowAddAccountDialog = true;
    },

    //试用账户的编辑按钮点击事件
    doEditTryAccountAction() {
      this.isShowEditTryAccountDialog = true;
    },

    //正式账户的编辑按钮点击事件
    doEditOfficialAccountAction(item) {
      this.editItem = item;
      console.log("点击的正式账户的条目是:", this.editItem);
      this.isShowEditOfficialAccountDialog = true;
    },
    //刷新界面
    refreshPage() {
      this.$emit("refreshPage");
    },
    //外层点击事件，关闭试用范围
    doOutClick() {
      if (!this.isEmpty(this.$refs.try_account)) {
        this.$refs.try_account.close();
      }
    },
    //关闭新增加账户弹框
    closeAddAccount() {
      this.isShowAddAccountDialog = false;
    },
  },
  computed: {},
  components: {
    TryAccount,
    OfficialAccount,
    AddAccount,
    EditTryAccountDialog,
    EditOfficialAccountDialog,
  },
  //每个级别数据
  props: ["subItem", "sid", "pid"],
};
</script>
<style lang="scss" scoped>
.sub_level {
  margin-bottom: 16px;
  border: 1px dashed $line_color;
  border-radius: 2px;
  margin-right: 10px;

  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .empty_view {
    min-height: 100px;
  }
  .trial_empty_view {
    min-height: 40px;
    border-bottom: 1px dashed $line_color;
  }

  .empty_tv {
    font-size: 12px;
    color: gray;
  }
}
</style>
