<!-- 新增账户 -->
<!-- 
   对应的逻辑:如果该级别有正式账户数据,那么试用账户编辑组件不显示
              正式账户的显示 任何情况下都进行显示
 -->
<template>
  <div class="add_account_dialog" @click.stop="clickOut">
    <CommonDialog
      @close="close"
      :showDialog="showDialog"
      :title="title"
      :width="width"
    >
      <template v-slot:content>
        <div class="real_content">
          <!-- 加载视图部分 -->
          <Loading style="min-height: 300px" v-if="showLoading"></Loading>

          <!-- 内容部分 -->
          <div v-else>
            <!-- 课程名称 -->
            <div class="margin_left_10 margin_bottom_12 margin_top_20">
              课程：{{ subName }}
            </div>

            <!-- 试用账户编辑 如果账户是正式账户的情况下,不显示 -->
            <TryAccountEdit
              :pid="pid"
              ref="try_account_edit"
              @doChoosedAccount="doChoosedAccount"
              v-if="trial"
              :trial="trial"
            ></TryAccountEdit>

            <!-- 正式账户编辑 任何情况下都需要显示 -->
            <OfficialAccountEdit
              :pid="pid"
              @doChoosedAccount="doChoosedAccount"
              :normal="normal"
            ></OfficialAccountEdit>

            <!-- 提交按钮部分 -->
            <CommitButton
              ref="commit_bt"
              @close="close"
              @doCommitAction="doCommitAction"
            ></CommitButton>
          </div>
        </div>
      </template>
    </CommonDialog>
  </div>
</template>
<script>
//引入通用Dialog对话框
import CommonDialog from "@/components/CommonDialog";

//引入测试账户的编辑组件
import TryAccountEdit from "@/components/updateproject/common/TryAccountEdit";

//引入正式账户的编辑组件
import OfficialAccountEdit from "@/components/updateproject/common/OfficialAccountEdit";

//引入全局的返回和提交按钮组件
import CommitButton from "@/components/CommitButton";

//引入混入
import EditProduct from "@/mixin/EditProduct.js";
export default {
  mixins: [EditProduct],
  data() {
    return {
      //弹框的宽度
      width: "920px",

      //是否显示加载布局
      showLoading: true,

      //服务器返回的数据,保存的时候回传此JSON
      postData: null,

      //测试账户数据
      trial: null,

      //正式账户数据
      normal: null,
    };
  },
  created() {
    //请求数据
    this.http();
  },
  methods: {
    /**
     * sid 校区id
     * pid 平台id
     * subId 科目id
     */
    http() {
      let param = this.getHttpParams();
      param.type = 7;
      param.sid = this.sid;
      param.pid = this.pid;
      param.subId = this.subId;
      this.$http.fetchPost(this.$api.SCHOOL_JURISDICTION, param).then((res) => {
        let result = res.data;
        if (result.state == 200) {
          //获取正式账户数据和试用账户数据
          this.postData = result.data;
          this.trial = result.data.trial;
          this.normal = result.data.normal;

          if (!this.isEmpty(this.trial)) {
            //组装试用账户的数据
            //试用账户是否选中,默认不选中,此值同样用于控制试用账户数的输入框是否可操作
            //以及试用范围是否可操作
            this.$set(this.trial, "choosed", false);

            //试用账户存在的情况下是一定可以编辑使用和正式账户的
            //所以试用账户的选中必须显示
            this.$set(this.trial, "isShow", true);
          }

          if (!this.isEmpty(this.normal)) {
            //组装正式账户的数据
            if (!this.isEmpty(this.trial)) {
              //有试用账户的时候正式账户的选中按钮显示
              this.$set(this.normal, "isShow", true);
              //有使用账号的情况下默认不选中
              this.$set(this.normal, "choosed", false);
            } else {
              //没有试用账户的时候正式账户的选中按钮不显示
              this.$set(this.normal, "isShow", false);

              //没有使用账号正式账户 选中
              this.$set(this.normal, "choosed", true);
            }

            //所有级别是否选中,默认是false,此值同样用于控制所有级别的有效账户数,账户有效期是否可操作
            this.$set(this.normal, "selected", false);

            //所有级别的教师选中时间,默认空数组
            this.$set(this.normal, "time", []);
            if (this.pid == 4 || this.pid == 5) {
              //程序蛙少儿编程和模考中心需要配置学生账户时间
              this.$set(this.normal, "studentTime", []);
            }

            //配置每个子级别的控制状态
            let levelArray = this.normal.levelArray;
            levelArray.forEach((item) => {
              //每个子级别是否选中,默认false，此值同样用于控制所有级别的有效账户数,账户有效期是否可操作
              this.$set(item, "selected", false);
              //子级别的教师选中时间,默认空数组
              this.$set(item, "time", []);
              if (this.pid == 4 || this.pid == 5) {
                //程序蛙少儿编程和模考中心需要配置学生账户时间
                this.$set(item, "studentTime", []);
              }
            });
          }

          //显示视图
          this.showLoading = false;
        } else {
          this.showErrorMsg(this.getMesage(result.state));
        }
      });
    },

    //关闭弹框
    close() {
      //触发父组件的关闭方法
      this.$emit("close");
    },

    //保存
    doCommitAction() {
      //判断数据是否完整
      if (this.judgeData(this.pid)) {
        // console.log("设置好的数据是:", this.postData);
        //请求服务器上传数据
        this.$refs.commit_bt.showLoading = true;
        let param = this.getHttpParams();
        param.type = 8;
        param.sid = this.sid;
        param.addInfo = JSON.stringify(this.postData);
        this.$http
          .fetchPost(this.$api.SCHOOL_JURISDICTION, param)
          .then((res) => {
            this.$refs.commit_bt.showLoading = false;
            let result = res.data;
            if (result.state == 200) {
              //上传成功,关闭新增弹框,刷新页面数据
              this.showSuccessMsg("保存成功");
              this.$emit("refreshPage");
              this.close();
            } else {
              this.showErrorMsg(this.getMesage(result.state) + ",保存失败");
            }
          });
      }
    },

    //试用账户和正式账户的选中回调事件
    doChoosedAccount(type) {
      if (type == 1) {
        //正式账户的选中事件
        if (this.normal.choosed) {
          //选中
          this.trial.choosed = false;
          //清空试用账户的状态
          this.clearTiralState();
        } else {
          //取消
          this.clearNormalState();
        }
      } else if (type == 2) {
        //试用账户的选中事件
        if (this.trial.choosed) {
          //选中
          this.normal.choosed = false;

          //清空正式账户的状态
          this.clearNormalState();
        } else {
          //取消
          //清空试用账户的状态
          this.clearTiralState();
        }
      }
    },

    //外层点击事件,关闭试用范围
    clickOut() {
      if (!this.isEmpty(this.$refs.try_account)) {
        this.$refs.try_account.close();
      }
    },
  },
  computed: {},
  components: {
    CommonDialog,
    TryAccountEdit,
    OfficialAccountEdit,
    CommitButton,
  },
  props: ["showDialog", "title", "sid", "pid", "subId", "subName"],
};
</script>
<style lang="scss" scoped>
.add_account_dialog {
  .real_content {
    margin: 0 auto;
  }

  ::v-deep .el-dialog {
    top: 50%;
  }

  ::v-deep .el-icon-close:before {
    cursor: pointer;
  }
}
</style>
