<!-- 编辑的时间选择有2个事件选择器组成,一个开始时间,一个结束时间,并且可以单独控制是否可以选择 -->
<template>
  <div class="new_time_picker">
    <!-- 开始日期 -->
    <el-date-picker :disabled="isArrowStart" v-model="startTime" type="date" placeholder="选择开始日期" @change="changeStartTime"> </el-date-picker>

    <div class="margin_left_10 margin_right_10">至</div>

    <!-- 结束日期 -->
    <el-date-picker v-model="endTime" type="date" placeholder="选择结束日期" @change="changeEndTime"> </el-date-picker>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //开始日期
      startTime: "",
      //结束日期
      endTime: "",
      //开始日期是否可以点击
      isArrowStart: true
    };
  },
  created() {},
  mounted() {
    // console.log("传入的时间是:", this.dateTime);
    this.initState();
  },
  methods: {
    //初始化开始时间和结束时间,同时初始化开始日期是否可以进行选择
    //如果开始日期小于当前等于当前日期,则开始日期不可编辑
    //结束日期都可以编辑但是要保证结束日期必须大于开始日期
    initState() {
      this.startTime = this.dateTime[0];
      this.endTime = this.dateTime[1];
      //开始日期的操作是否禁用
      this.isArrowStart = this.compareStartTime();
    },

    //开始时间的选中事件
    changeStartTime(val) {
      //同步传入的值
      this.$emit("sysStartTime", val);
    },

    //结束时间的选中事件
    changeEndTime(val) {
      //同步传入的值
      this.$emit("sysEndTime", val);
    },

    //判断开始日期与当前日期的大小
    compareStartTime() {
      let nowTime = new Date().valueOf();
      let stratTime = new Date(this.startTime).valueOf();
      if (nowTime > stratTime) {
        return true;
      } else {
        return false;
      }
    }
  },
  computed: {},
  watch: {},
  props: ["dateTime"]
};
</script>

<style lang="scss" scoped>
.new_time_picker {
  display: flex;
  align-items: center;
  ::v-deep .el-input {
    width: 160px;
  }
}
</style>
