//取消提交按钮
<template>
    <div class="commit_button">
        <div class="cancel com_bt" @click.stop="doCancelAction">
            <span>取</span><span class="margin_left_12">消</span>
        </div>
        <!-- 确定 -->
        <el-button :loading="showLoading" class="commit com_bt" @click.stop="doCommitAction">
            <span>保</span><span class="margin_left_12">存</span>
        </el-button>
    </div>
</template>
<script>
export default {
    data() {
        return {
            //提交状态
            showLoading: false,
        };
    },
    created() {},
    methods: {
        //取消
        doCancelAction() {
            this.$emit("close");
        },

        //提交
        doCommitAction() {
            this.$emit("doCommitAction");
            // this.showLoading = true;
        },
    },
    computed: {},
    components: {},
    props: [],
};
</script>
<style lang="scss" scoped>
.commit_button {
    // position: absolute;
    // bottom: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .com_bt {
        width: 110px;
        border-radius: 4px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .cancel {
        background: #dcdcdc;
        margin-right: 120px;
        color: black;
    }
    .commit {
        background: #409eff;
        color: white;
    }
}
</style>