// 加盟课程管理界面
<template>
  <div class="manager_join_lessons">
    <div class="detailed_model_content" v-if="showLoading == 1">
      <!--  顶部的学校信息 -->
      <SchoolInfo :schoolInfo="schoolInfo"></SchoolInfo>

      <!-- 项目切换的导航组价 -->
      <ProjcetNavBar :projectArr="projectArr" @doItemClick="doItemClick"></ProjcetNavBar>

      <!-- 用于显示切换项目组件的部分 -->
      <div class="show_project_com" v-if="levelArr">
        <!-- <el-scrollbar> -->
        <SubLevel @refreshPage="refreshPage" :pid="pid" :sid="sid" :subItem="item" v-for="item in levelArr" :key="item.subId"></SubLevel>
        <!-- </el-scrollbar> -->
      </div>
    </div>

    <div class="detailed_model_content" v-if="showLoading == -1">
      <LoadingView></LoadingView>
    </div>
  </div>
</template>
<script>
//学校信息
import SchoolInfo from "@/components/updateproject/common/SchoolInfo.vue";

// 项目切换的导航组件
import ProjcetNavBar from "@/components/updateproject/ProjectNavBar.vue";

//引入通用子级别
import SubLevel from "@/components/updateproject/common/SubLevel";

//引入加载组件
import LoadingView from "@/components/Loading.vue";

export default {
  data() {
    return {
      //是否显示加载视图
      showLoading: 1,

      //导航条的数据
      projectArr: [],

      //导航栏的位置
      curPos: 0,

      //携带的学校先关信息
      schoolInfo: {},

      //当前课程所有级别数据
      levelArr: [],

      //传递的学校id以及pid
      sid: "",
      pid: "",
    };
  },
  mounted() {
    //获取数据
    this.initData();

    //请求数据
    this.http();
  },
  created() {},
  methods: {
    //初始化数据
    initData() {
      //取出学校信息
      this.schoolInfo = JSON.parse(localStorage.getItem(this.$key.SCHOOL_INFO_STR));

      //取出学校id
      this.sid = this.schoolInfo.school_id;
    },

    //项目切换
    doItemClick(pos) {
      this.curPos = pos;
      //同步pid
      this.pid = this.projectArr[this.curPos].pid;
      //请求对应位置的级别数据
      this.httpLevelData(this.projectArr[this.curPos].pid);
    },

    //查询所有平台信息(标识校区是否加盟)
    http() {
      let param = this.getHttpParams();
      param.type = 5;
      param.sid = this.schoolInfo.school_id;
      this.$http.fetchPost(this.$api.SCHOOL_JURISDICTION, param).then((res) => {
        if (res.data.state == 200) {
          //取出所有课程信息
          let server = res.data.data;
          this.projectArr = server;

          if (!this.isEmpty(this.projectArr)) {
            //取出需要传递的pid
            this.pid = this.projectArr[this.curPos].pid;
            //默认请求第一个级别的数据
            this.httpLevelData(this.projectArr[this.curPos].pid);
          }
        } else {
          this.showErrorMsg(this.getMesage(res.data.state));
        }
      });
    },

    //获取数据
    httpLevelData(pid) {
      let param = this.getHttpParams();
      param.type = 6;
      param.sid = this.schoolInfo.school_id;
      param.pid = pid;
      this.$http.fetchPost(this.$api.SCHOOL_JURISDICTION, param).then((res) => {
        let result = res.data;
        if (result.state == 200) {
          //获取所有级别的数据
          this.levelArr = result.data;
        }
      });
    },

    //刷新界面的方法
    refreshPage() {
      //请求对应位置的级别数据
      // this.httpLevelData(this.projectArr[this.curPos].pid);
      this.http();
    },
  },
  computed: {},
  components: {
    ProjcetNavBar,
    LoadingView,
    SchoolInfo,
    SubLevel,
  },
};
</script>
<style lang="scss" scoped>
.manager_join_lessons {
  height: calc(100vh - 77px);
  margin: 0 14px;
  min-width: 1200px;
  .detailed_model_content {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    //用于存放组件部分
    .show_project_com {
      flex: 1;
      height: calc(100vh - 77px - 130px);
      margin-bottom: 10px;
      overflow-y: auto;
    }
  }
}
</style>
