<!-- 试用账户 -->
<template>
  <div class="try_account">
    <div class="margin_top_10 flex_h margin_bom_10">
      <!-- <i @click.stop='doClick' :class="['check_el','iconfont',isTrialAccount?'icon-xuanzhong':'icon-danxuanweixuanzhong']"></i> -->
      <span class="text_fw">试用账户</span>

      <!-- 试用老师账户数 -->
      <span class="margin_left_20 tv_red">*</span
      ><span>试用教师账户数：{{ trial.teacherNum }}</span>

      <!-- 使用账户数  -->
      <div class="margin_left_12">
        <span>已使用：</span>
        <span style="color: #f00; margin-right: 20px">{{
          trial.teacherUsed
        }}</span>
      </div>

      <!-- 试用学生账户数 特定的级别才显示 程序蛙和悟空寻宝才显示使用学生账户 -->
      <div v-if="pid == 4 || pid == 9 || pid == 12" class="flex_h">
        <span class="margin_left_20 tv_red">*</span
        ><span>试用学生账户数：{{ trial.studentNum }}</span>

        <!-- 试用学生账户数  -->
        <div class="margin_left_12">
          <span>已使用：</span>
          <span style="color: #f00; margin-right: 20px">{{
            trial.studentUsed
          }}</span>
        </div>
      </div>

      <!-- 编辑按钮,如果该级别是正式账户,则编辑按钮不显示 如果edit状态为1表示试用还未过期-->
      <span
        @click.stop="doEditAciton"
        class="edit_bt margin_left_20"
        v-if="mode == 0 && this.trial.edit == 1"
        >编辑</span
      >

      <!-- 试用范围  :disableAction='isTrialAccount'-->
      <ChooseRange
        disableAction="false"
        class="margin_left_100"
        :chooseData="trial.trialRange"
        ref="choose_t_level"
      ></ChooseRange>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {},
  methods: {
    //编辑按钮的点击事件
    doEditAciton() {
      //可编辑
      this.$emit("doEditAction");
    },

    //关闭试用范围的方法
    close() {
      this.$refs.choose_t_level.doCancel();
    },
  },
  computed: {},
  components: {},

  //加盟状态以及使用账户数据
  props: ["mode", "trial", "pid"],
};
</script>
<style lang="scss" scoped>
.try_account {
  border-bottom: 1px dashed $line_color;
  padding-bottom: 10px;
  // 选中按钮
  .check_el {
    color: #409eff;
    margin-right: 6px;
    cursor: pointer;
  }
}
</style>
