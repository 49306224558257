// 正式账户的编辑弹框
<template>
  <div class="edit_official_account">
    <CommonDialog @close="close" :showDialog="showDialog" :title="title" :width="width">
      <template v-slot:content>
        <div class="real_content">
          <!-- 内容-->
          <div class="edit_official_account_content">
            <div class="com_dv">
              <!-- 课程名称 -->
              <div class="margin_top_20">课程级别名称：{{ editItem.levelName }}</div>

              <!-- 试用教师账户数 -->
              <div class="com_dv margin_top_30 item_com_div" v-if="isShowTeacher">
                <span>有效教师账户数：</span>

                <!-- 输入的试用教师账户数 -->
                <el-input onkeyup="value=value.replace(/[^\d]/g,'')" clearable v-model.number="teacherNum" placeholder="请输入账户数"></el-input>

                <!-- 账户有效期 -->
                <span class="margin_left_20">账户有效期：</span>

                <!-- 账号有效期的日期选择 -->
                <!-- <CommonDatePicker :disabledDate="disabledDate" v-model="teacherTime"></CommonDatePicker> -->

                <DatePick :dateTime="teacherTime" @sysStartTime="sysTeacherStartTime" @sysEndTime="sysTeacherEndTime"></DatePick>
              </div>

              <!-- 试用学生账户数,需要根据课程级别来显示 程序蛙和模考中心才显示 -->
              <div class="com_dv margin_top_30 margin_bottom_20 item_com_div" v-if="isShowStu">
                <span>有效学生账户数：</span>

                <!-- 输入的试用学生账户数 -->
                <el-input onkeyup="value=value.replace(/[^\d]/g,'')" clearable v-model.number="studentNum" placeholder="请输入账户数"></el-input>

                <!-- 账户有效期 -->
                <span class="margin_left_20">账户有效期：</span>

                <!-- disabledDate='true' -->
                <!-- <CommonDatePicker :disabledDate="disabledDate" v-model="studentTime"></CommonDatePicker> -->
                <DatePick :dateTime="studentTime" @sysStartTime="sysStuStartTime" @sysEndTime="sysStuEndTime"></DatePick>
              </div>
            </div>

            <!-- 提交按钮部分 -->
            <div class="commit_button margin_top_20">
              <div class="cancel com_bt" @click.stop="close"><span>取</span><span class="margin_left_12">消</span></div>
              <el-button :loading="showLoading" class="commit com_bt" @click.stop="doCommitAction">
                <span>保</span><span class="margin_left_12">存</span>
              </el-button>
            </div>
          </div>
        </div>
      </template>
    </CommonDialog>
  </div>
</template>
<script>
//引入通用Dialog对话框
import CommonDialog from "@/components/CommonDialog";
// 日期选择器
import CommonDatePicker from "@/components/CommonDatePicker.vue";

// 引入新的日期选择组件
import DatePick from "@/components/pick/DatePick.vue";
export default {
  data() {
    return {
      //日期选择组件是否允许选择的状态值
      disabledDate: false,

      //宽度
      width: "740px",

      //试用的教师账户数
      teacherNum: "",

      //是否显示编辑老师部分
      isShowTeacher: false,

      //试用的教师账户有效期
      teacherTime: [],

      //试用的学生账户数
      studentNum: "",

      //是否显示编辑学生部分
      isShowStu: false,

      //试用的学生账户有效期
      studentTime: [],

      //保存按钮的加载loading
      showLoading: false
    };
  },
  created() {
    this.sysPageData();
  },
  mounted() {},
  methods: {
    //关闭
    close() {
      this.$emit("close");
    },

    //进入的时候同步回显数据
    sysPageData() {
      if (!this.isEmpty(this.editItem)) {
        this.teacherNum = this.editItem.teacherNum;
        if (this.teacherNum != 0) {
          this.isShowTeacher = true;
        }
        this.teacherTime.push(this.editItem.teacherStartDate);
        this.teacherTime.push(this.editItem.teacherEndDate);

        if (this.pid == 4 || this.pid == 5 || this.pid == 9 || this.pid == 12) {
          if (!this.isEmpty(this.editItem.studentNum)) {
            this.studentNum = this.editItem.studentNum;
            if (this.studentNum != 0) {
              this.isShowStu = true;
            }
          }
          if (!this.isEmpty(!this.editItem.studentStartDate) && !this.isEmpty(!this.editItem.studentEndDate)) {
            this.studentTime.push(this.editItem.studentStartDate);
            this.studentTime.push(this.editItem.studentEndDate);
          }
        }
      }
    },

    //保存
    doCommitAction() {
      // console.log("修改后的数据是:", this.teacherTime);
      //判断数据的完整性并进行上传处理
      if (this.isShowTeacher) {
        // if (this.teacherNum == 0) {
        //     this.showWarnMsg("输入的教师账户数必须大于0");
        //     return false;
        // }
        if (this.isEmpty(this.teacherNum)) {
          this.showWarnMsg("未输入有效教师账户数");
          return false;
        }
        //判断输入的试用教师账户数的规则 编辑账户数只能大于等于已使用的次数
        if (parseInt(this.teacherNum) < parseInt(this.editItem.teacherNum) - parseInt(this.editItem.teacherBalance)) {
          this.showWarnMsg("输入的教师账户数必须大于等于已经使用的教师账户数");
          return false;
        }

        if (this.teacherTime.length == 0) {
          this.showWarnMsg("未设置有效教师账户有效期");
          return false;
        }

        //判断一下有效教师账户的结束时间是否大于开始时间
        if (new Date(this.teacherTime[1]).valueOf() < new Date(this.teacherTime[0]).valueOf()) {
          this.showWarnMsg("教师账户有效期结束时间必须大于开始时间");
          return false;
        }
      }

      if (this.isShowStu) {
        //程序蛙和模考还需要判定输入学生的输入数量
        if (parseInt(this.studentNum) < parseInt(this.editItem.studentNum) - parseInt(this.editItem.studentBalance)) {
          this.showWarnMsg("输入的学生账户数必须大于等于已经使用的学生账户数");
          return false;
        }

        if (this.studentTime.length == 0) {
          this.showWarnMsg("未设置有效学生账户有效期");
          return false;
        }

        //判断一下有效学生账户的结束时间是否大于开始时间
        if (new Date(this.studentTime[1]).valueOf() < new Date(this.studentTime[0]).valueOf()) {
          this.showWarnMsg("学生账户有效期结束时间必须大于开始时间");
          return false;
        }
      }

      if (this.hasUpdate()) {
        //表示修改数据,请求服务器,上传数据
        this.showLoading = true;
        let param = this.getHttpParams();
        param.type = 9;
        param.oid = this.editItem.orderId;
        param.teacherNum = this.teacherNum;
        param.teacherStartDate = this.getPostData(this.teacherTime[0]);
        param.teacherEndDate = this.getPostData(this.teacherTime[1]);

        if (this.pid == 4 || this.pid == 5 || this.pid == 9 || this.pid == 12) {
          //学生账户数和有效期间
          param.studentNum = this.studentNum;
          param.studentStartDate = this.getPostData(this.studentTime[0]);
          param.studentEndDate = this.getPostData(this.studentTime[1]);
        }

        this.$http.fetchPost(this.$api.SCHOOL_JURISDICTION, param).then(res => {
          this.showLoading = false;
          let result = res.data;
          if (result.state == 200) {
            //上传成功,关闭新增弹框,刷新页面数据
            this.showSuccessMsg("保存成功");
            this.$emit("refreshPage");
            this.close();
          } else {
            this.showErrorMsg(this.getMesage(result.state) + ",保存失败");
          }
        });
      } else {
        //未修改信息
        this.showWarnMsg("未修改数据,无需进行保存");
      }
    },

    //判断编辑账户是否修改
    hasUpdate() {
      //修改了教师有效账户数
      if (this.teacherNum != this.editItem.teacherNum) {
        return true;
      }
      //修改了教师有效账户数的有效期
      if (this.getPostData(this.teacherTime[0]) != this.editItem.teacherStartDate || this.getPostData(this.teacherTime[1]) != this.editItem.teacherEndDate) {
        return true;
      }

      if (this.pid == 4 || this.pid == 5 || this.pid == 9 || this.pid == 12) {
        //修改了学生有效账户数
        if (this.studentNum != this.editItem.studentNum) {
          return true;
        }

        //修改了学生有效账户数的有效期
        if (this.getPostData(this.studentTime[0]) != this.editItem.studentStartDate || this.getPostData(this.studentTime[1]) != this.editItem.studentEndDate) {
          return true;
        }
      }

      return false;
    },

    //格式化时间
    getPostData(time) {
      var date = new Date(time);
      var y = date.getFullYear();
      var m = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
      var d = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      return y + "-" + m + "-" + d;
    },

    //同步有效教师账户数的开始时间
    sysTeacherStartTime(startTime) {
      this.teacherTime[0] = startTime;
    },

    //同步有效教师账户数的结束时间
    sysTeacherEndTime(endTime) {
      this.teacherTime[1] = endTime;
    },

    //同步有效学生账户数的开始时间
    sysStuStartTime(startTime) {
      this.studentTime[0] = startTime;
    },

    //同步有效学生账户数的结束时间
    sysStuEndTime(endTime) {
      this.studentTime[1] = endTime;
    }
  },
  components: {
    CommonDialog,
    CommonDatePicker,
    DatePick
  },
  props: ["showDialog", "title", "editItem", "pid"]
};
</script>
<style lang="scss" scoped>
.edit_official_account {
  ::v-deep .el-dialog {
    top: 50%;
  }

  .real_content {
    .item_com_div {
      display: flex;
      align-items: center;
    }
    .edit_official_account_content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .commit_button {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 40px;
        margin-top: 80px;

        .com_bt {
          width: 120px;
          border-radius: 4px;
          height: 34px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
        .cancel {
          background: #dcdcdc;
          margin-right: 80px;
          color: black;
        }
        .commit {
          background: #169bd5;
          color: white;
        }
      }

      ::v-deep .el-input__inner {
        height: 28px;
        line-height: 28px;
        // padding-left: 6px;
      }

      ::v-deep .el-input__icon {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      ::v-deep .el-input {
        width: 140px;
        font-size: 12px;
      }

      ::v-deep .el-input__suffix {
        right: 0px;
      }
    }
  }

  ::v-deep .el-icon-close:before {
    cursor: pointer;
  }
}
</style>
