<!-- 学校信息 -->
<template>
  <div class="top_school_info">
    <!-- 学校名称,学校编码,联系手机号,联系人姓名 -->
    <div class="com_h margin_top_10">
      <span>学校名称：{{ schoolInfo.school_name }}</span>
      <span class="margin_left_60">学校编码：{{ schoolInfo.school_id }}</span>
      <span class="margin_left_60"
        >联系手机号：{{ schoolInfo.contacts_tel }}</span
      >
      <span class="margin_left_60"
        >联系人姓名：{{ schoolInfo.contacts_name }}</span
      >
    </div>
    <!-- 加盟课程 -->
    <div class="com_h margin_top_10">
      <span>加盟课程：</span>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {},
  methods: {},
  computed: {},
  components: {},
  props: ["schoolInfo"],
};
</script>
<style lang="scss" scoped>
.top_school_info {
  .com_h {
    display: flex;
    align-items: center;
  }
}
</style>
