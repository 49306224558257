// 试用账户的编辑弹框
<template>
  <div class="edit_try_account">
    <CommonDialog
      @close="close"
      :showDialog="showDialog"
      :title="title"
      :width="width"
    >
      <template v-slot:content>
        <div class="real_content">
          <!-- 内容 -->
          <div class="edit_try_account_content">
            <div>
              <!-- 课程名称 -->
              <div class="margin_top_20">课程：{{ subItem.subName }}</div>

              <!-- 试用教师账户数 -->
              <div class="com_dv margin_top_20">
                <span>试用教师账户数：</span>
                <!-- 输入的试用教师账户数 -->
                <el-input
                  onkeyup="value=value.replace(/[^\d]/g,'')"
                  clearable
                  v-model.number="teacherNum"
                  placeholder="请输入账户数"
                ></el-input>
              </div>

              <!-- 试用学生账户数,需要根据课程级别来显示 只有pid==4程序蛙的时候和pid==9悟空寻宝才有试用学生 -->
              <div class="com_dv margin_top_20" v-if="pid == 4 || pid == 9 || pid == 12">
                <span>试用学生账户数：</span>
                <!-- 输入的试用教师账户数 -->
                <el-input
                  onkeyup="value=value.replace(/[^\d]/g,'')"
                  clearable
                  v-model.number="studentNum"
                  placeholder="请输入账户数"
                ></el-input>
              </div>
            </div>

            <!-- 提交按钮部分 -->
            <div class="commit_button margin_top_30">
              <div class="cancel com_bt" @click.stop="close">
                <span>取</span><span class="margin_left_12">消</span>
              </div>
              <el-button
                :loading="showLoading"
                class="commit com_bt"
                @click.stop="doCommitAction"
              >
                <span>保</span><span class="margin_left_12">存</span>
              </el-button>
            </div>
          </div>
        </div>
      </template>
    </CommonDialog>
  </div>
</template>
<script>
//引入通用Dialog对话框
import CommonDialog from "@/components/CommonDialog";
export default {
  data() {
    return {
      //宽度
      width: "380px",
      //试用的教师账户数
      teacherNum: "",
      //试用的学生账户数
      studentNum: "",
      //保存按钮的加载loading
      showLoading: false,
    };
  },
  created() {
    // console.log("编辑的条目是:", this.subItem);
    this.sysPageData();
  },
  mounted() {},
  methods: {
    //同步数据
    sysPageData() {
      if (!this.isEmpty(this.subItem)) {
        //老师数量
        this.teacherNum = this.subItem.trial.teacherNum;
        if (this.pid == 4 || this.pid == 9 || this.pid == 12) {
          //学生数量
          this.studentNum = this.subItem.trial.studentNum;
        }
      }
    },
    //关闭
    close() {
      this.$emit("close");
    },

    //保存
    doCommitAction() {
      //判断数据的完整性并进行上传处理
      // if (this.teacherNum == 0) {
      //     this.showWarnMsg("输入的试用教师账户数必须大于0");
      //     return false;
      // }
      if (this.isEmpty(this.teacherNum)) {
        this.showWarnMsg("未输入试用教师账户数");
        return false;
      }
      //判断输入的试用教师账户数的规则 编辑账户数只能大于等于已使用的次数
      if (
        parseInt(this.teacherNum) < parseInt(this.subItem.trial.teacherUsed)
      ) {
        this.showWarnMsg("输入的教师账户数必须大于等于已经使用的教师账户数");
        return false;
      }

      if (this.pid == 4 || this.pid == 9 || this.pid == 12) {
        //程序蛙pid==4和悟空寻宝pid==9还需要判断学生账户数
        if (this.isEmpty(this.studentNum)) {
          this.showWarnMsg("未输入试用老师账户数");
          return false;
        }

        //判断输入的试用教师账户数的规则 编辑账户数只能大于等于已使用的次数
        if (
          parseInt(this.studentNum) < parseInt(this.subItem.trial.studentUsed)
        ) {
          this.showWarnMsg("输入的学生账户数必须大于等于已经使用的学生账户数");
          return false;
        }
      }

      if (this.hasUpdate()) {
        //表示修改数据,请求服务器,上传数据
        this.showLoading = true;
        let param = this.getHttpParams();
        param.type = 9;
        param.pid = this.pid;
        param.sid = this.sid;
        param.subId = this.subItem.subId;
        param.teacherNum = this.teacherNum;
        if (this.pid == 4 || this.pid == 9 || this.pid == 12) {
          param.studentNum = this.studentNum;
        }

        this.$http
          .fetchPost(this.$api.SCHOOL_JURISDICTION, param)
          .then((res) => {
            this.showLoading = false;
            let result = res.data;
            if (result.state == 200) {
              //上传成功,关闭新增弹框,刷新页面数据
              this.showSuccessMsg("保存成功");
              this.$emit("refreshPage");
              this.close();
            } else {
              this.showErrorMsg(this.getMesage(result.state) + ",保存失败");
            }
          });
      } else {
        //未修改信息
        this.showWarnMsg("未修改数据,无需进行保存");
      }
    },

    //判断编辑账户是否修改
    hasUpdate() {
      //修改了教师有效账户数
      if (this.teacherNum != this.subItem.trial.teacherNum) {
        return true;
      }
      if (this.pid == 4 || this.pid == 9|| this.pid == 12) {
        //修改了学生有效账户数
        if (this.studentNum != this.subItem.trial.studentNum) {
          return true;
        }
      }

      return false;
    },
  },
  components: {
    CommonDialog,
  },
  props: ["showDialog", "title", "subItem", "pid", "sid"],
};
</script>
<style lang="scss" scoped>
.edit_try_account {
  ::v-deep .el-dialog {
    top: 50%;
  }

  .real_content {
    .edit_try_account_content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .commit_button {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;

        .com_bt {
          width: 100px;
          border-radius: 4px;
          height: 34px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
        .cancel {
          background: #dcdcdc;
          margin-right: 30px;
          color: black;
        }
        .commit {
          background: #169bd5;
          color: white;
        }
      }

      ::v-deep .el-input__inner {
        height: 28px;
        line-height: 28px;
        padding-left: 6px;
      }

      ::v-deep .el-input__icon {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      ::v-deep .el-input {
        width: 120px;
        font-size: 12px;
      }

      ::v-deep .el-input__suffix {
        right: 0px;
      }
    }
  }

  ::v-deep .el-icon-close:before {
    cursor: pointer;
  }
}
</style>
