<!-- 正式账户 -->
<template>
  <div class="official_account margin_top_10">
    <!-- 选中按钮 -->
    <!-- <i @click.stop='doClick' :class="['check_el','iconfont',isOfficialAccount?'icon-xuanzhong':'icon-danxuanweixuanzhong']"></i> -->
    <span class="margin_top_up text_fw">正式账户</span>
    <!-- 时间轴 -->
    <div class="time_line margin_top_2">
      <div class="item_account" v-for="(item, index) in normal" :key="index">
        <!-- 左边的时间轴标记和进度线条 -->
        <div class="left">
          <span class="circle margin_top_1"></span>
          <div class="line" v-if="index != normal.length - 1"></div>
        </div>

        <!-- 账号创建时间 -->
        <div class="time margin_left_10 margin_top_up">
          {{ item.createTime }}
        </div>

        <!-- 中间的新增信息 -->
        <div class="mid_info margin_left_20 margin_top_up">
          <div class="item_mid" v-for="(obj, pos) in item.joinList" :key="pos">
            <!-- 左边的新增信息 -->
            <div class="left_msg">
              <!-- 新增信息 {{subName}} -->
              <div>新增了{{ obj.levelName }}</div>
              <!-- 教师信息 -->
              <div
                v-if="obj.teacherNum != 0"
                class="margin_top_10 margin_bom_10"
              >
                {{ obj.teacherNum }}个教师账户（账户期限：{{
                  obj.teacherStartDate
                }}~{{ obj.teacherEndDate }}），已使用：{{
                  obj.teacherNum - obj.teacherBalance
                }}个，剩余：{{ obj.teacherBalance }}个。
              </div>
              <!-- 学生信息 -->
              <div v-if="obj.studentNum != 0">
                {{ obj.studentNum }}个学生账户（账户期限：{{
                  obj.studentStartDate
                }}~{{ obj.studentEndDate }}），已使用：{{
                  obj.studentNum - obj.studentBalance
                }}个，剩余：{{ obj.studentBalance }}个。
              </div>
            </div>
            <!-- 右边的编辑 -->
            <div class="edit">
              <span
                @click.stop="doEditAction(obj)"
                class="edit_bt margin_left_20"
                >编辑</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {},
  methods: {
    //正式账号的点击事件
    doClick() {},

    //正式账户条目的编辑点击事件
    doEditAction(item) {
      this.$emit("doEditAciton", item);
    },
  },
  computed: {},
  components: {},
  //正式账户的数据
  props: ["normal", "subName"],
};
</script>
<style lang="scss" scoped>
.official_account {
  padding-top: 10px;
  min-height: 160px;
  display: flex;
  // 选中按钮
  .check_el {
    color: #409eff;
    margin-right: 6px;
    cursor: pointer;
  }

  .time_line {
    flex: 1;
    display: flex;
    flex-direction: column;

    //条目
    .item_account {
      margin-left: 20px;
      display: flex;
      //线条
      .left {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        .circle {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: #409eff;
        }
        .line {
          width: 1px;
          flex: 1;
          background: $line_color;
        }
      }
      //时间
      .time {
        font-size: 12px;
      }

      //中间的信息
      .mid_info {
        display: flex;
        flex-direction: column;
        flex: 1;

        .item_mid {
          display: flex;
          align-items: center;
          .left_msg {
            display: flex;
            flex-direction: column;
            min-width: 540px;
            margin-bottom: 20px;
          }
        }
      }

      .has_bottom {
        padding-bottom: 30px;
      }
    }
  }
}
</style>
