//项目切换的导航栏
<template>
  <div class="project_nav_bar">
    <div
      @click.stop="doItemClick(index)"
      :class="['item_nav', curpos == index ? 'nav_select' : 'nav_normal']"
      v-for="(item, index) in projectArr"
      :key="index"
    >
      <span>{{ item.name }}</span>
      <!-- 标识图标  -1已过期图标灰色  1已开通  -3未开通 -->
      <span
        :class="[
          { state_info_green: item.state == 1 },
          { state_info_gray: item.state == -1 },
        ]"
        class="margin_left_6 el-icon-check"
        v-if="item.state != -3"
      ></span>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      curpos: 0,
    };
  },
  created() {},
  methods: {
    //条目点击事件
    doItemClick(index) {
      if (this.curpos != index) {
        this.curpos = index;
        this.$emit("doItemClick", index);
      }
    },
  },
  computed: {},
  components: {},
  props: ["projectArr"],
};
</script>
<style lang="scss" scoped>
.project_nav_bar {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .item_nav {
    margin-right: 14px;
    display: flex;
    margin-top: 10px;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    padding: 0 12px;
    cursor: pointer;
  }

  .state_info_green {
    color: #45a312;
    font-weight: bold;
  }

  .state_info_gray {
    color: #e6e7e8;
    font-weight: bold;
  }

  .nav_select {
    padding-top: 8px;
    padding-bottom: 8px;
    background: #ff9901;
    color: white;
  }

  .nav_normal {
    padding-top: 7px;
    padding-bottom: 7px;
    border: 1px solid $line_color;
  }
}
</style>
